
"use client";

import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin, FaFacebookSquare } from "react-icons/fa";

function AboutUs() {
  return (
    <>
     <section className="px-4 py-8 animate-section-fade" id="about-us">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-5/12">
              <h2 className="text-3xl font-bold mb-8">
                About Us
              </h2>
              <p className="leading-relaxed mb-4 first-letter:text-[200%]">
                MPACER stands for ‘I AM PACER,’ symbolizing our mission to create solutions that
                streamline our clients’ businesses.
              </p>
              <p className="  leading-relaxed mt-0 mb-4 ">
                Since 2017, MPACER Solutions has specialized in building Drupal CMS web applications using
                decoupled tools and techniques. Our approach ensures a seamless user experience and a
                scalable, modular framework, empowering enterprises to boost revenue, reduce operating
                expenses, and enhance digital experiences.
              </p>
              <p className="  leading-relaxed mt-0 mb-4 ">
                Our passionate team of skilled professionals excels in web development, design,
                and digital strategy. We deliver innovative and robust solutions, focusing on intuitive
                user interfaces and understanding clients’ unique needs.
              </p>  <p className="  leading-relaxed mt-0 mb-4 ">
                We are committed to helping our clients achieve their business objectives and succeed in the
                digital world.
              </p>
            </div>

            <div className="w-full md:w-4/12 mr-auto ml-auto">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                <img
                  alt="..."
                  src="About-us.png"
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-2 mb-4">
                  <div className="text-center">
                    <p>Follow us on: </p>
                    <a aria-label="linkedinicon" href="https://www.linkedin.com/company/mpacersolutions" target='_blank' className='px-2'>
                      <div className="text-brand-blue text-3xl text-center inline-flex items-center justify-center w-16 h-16 shadow-lg rounded-full cursor-pointer transform hover:-translate-y-1 duration-300 hover:shadow-xl">
                        <FaLinkedin className='' />
                      </div>
                    </a>
                    <a aria-label="fbicon" href="https://www.facebook.com/people/MPacer-Solutions/100069582885209/" target='_blank' className='px-2'>
                      <div className="text-brand-blue text-3xl text-center inline-flex items-center justify-center w-16 h-16 shadow-lg rounded-full cursor-pointer transform hover:-translate-y-1 duration-300 hover:shadow-xl">
                        <FaFacebookSquare className='' />
                      </div>
                    </a>
                    <a aria-label="twittericon" href="https://x.com/MPACERSolutions" target='_blank' className='px-2'>
                      <div className=" text-brand-blue text-3xl text-center inline-flex items-center justify-center w-16 h-16 shadow-lg rounded-full cursor-pointer transform hover:-translate-y-1 duration-300 hover:shadow-xl">
                        <FaXTwitter className='' />
                      </div>
                    </a>
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default AboutUs;
import React from 'react';
import Hero from './Hero.tsx';
import Annourcement from './Annoucement.tsx';
import Article from './Article.tsx';
import AboutUs from './AboutUs.tsx';
import Services from './Services.tsx';
import WhyMpacer from './WhyMpacer.tsx';


function Home() {
  return (
    <main className='animate-section-fade'>
      <Hero />
      <Annourcement />
      <Services />
      <AboutUs />
      <WhyMpacer />
      <Article />
    </main>
  );
}

export default Home;

import Home from './components/Home.tsx';
import Article from './components/Article.tsx';
import Header from './components/Header.js';
import Footerpage from './components/Footerpage.tsx';
import AboutUs from './components/AboutUs.tsx';
import Services from './components/Services.tsx';
import WhyMpacer from './components/WhyMpacer.tsx';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  
import './index.css';

const App = () => {
  return (
    <>
      <Router>  
        <div className='flex flex-col justify-between min-h-screen'>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/why-mpacer" element={<WhyMpacer />} />
            <Route path="/articles" element={<Article />} />
          </Routes>
          <Footerpage />
        </div>
      </Router>
    </>
  );
};

export default App;

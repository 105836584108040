
"use client";
import {
  RiNumber1,
  RiNumber2,
  RiNumber3,
  RiNumber4,
  RiNumber5,
  RiNumber6,
  RiNumber7,
  RiNumber8,
  RiNumber9
} from "react-icons/ri";
import { ContactUsFormDrawer } from "./ContactUsFormDrawer.tsx";

function WhyMpacer() {
  return (
    <>
      <section className="px-4 py-8 bg-gradient-to-b from-white to-blue-100 animate-section-fade">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center mb-8">
            <div className=" text-white text-5xl inline-flex items-center justify-center w-16 h-16 mr-2 rounded-full bg-red-800 animated-brand-bg" >
              <img alt="MPACER Icon" src="mpacer-icon.svg" />
            </div>
            <h2 className="text-3xl font-bold text-center">Why MPACER</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <div className="absolute flex right-0 top-0 p-2 text-6xl text-red-800">
                <RiNumber1 />
              </div>
              <h2 className="text-xl font-semibold mb-5 pr-8">Expertise in Digital Transformation</h2>
              <p className="first-letter:text-[200%]">MPACER offers guidance through technical aspects, including choosing the right technology stack and finding cost-effective hosting solutions.</p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <div className="absolute flex right-0 top-0 p-2 text-6xl text-red-800">
                <RiNumber2 />
              </div>
              <h2 className="text-xl font-semibold mb-5 pr-8">Equal Importance to All Endeavors</h2>
              <p className="first-letter:text-[200%]">Regardless of the business size, MPACER treats every project with equal importance.</p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <div className="absolute flex right-0 top-0 p-2 text-6xl text-red-800">
                <RiNumber3 />
              </div>
              <h2 className="text-xl font-semibold mb-5 pr-8">Open Thought Sharing</h2>
              <p className="first-letter:text-[200%]">MPACER believes in sharing ideas without any financial strings attached.</p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <div className="absolute flex right-0 top-0 p-2 text-6xl text-red-800">
                <RiNumber4 />
              </div>
              <h2 className="text-xl font-semibold mb-5 pr-8">Specialization in Drupal CMS</h2>
              <p className="first-letter:text-[200%]">Since 2017, MPACER has specialized in building Drupal CMS web applications using decoupled tools and techniques.</p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <div className="absolute flex right-0 top-0 p-2 text-6xl text-red-800">
                <RiNumber5 />
              </div>
              <h2 className="text-xl font-semibold mb-5 pr-8">Seamless User Experience</h2>
              <p className="first-letter:text-[200%]">Our approach ensures a seamless user experience and a scalable, modular framework.</p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <div className="absolute flex right-0 top-0 p-2 text-6xl text-red-800">
                <RiNumber6 />
              </div>
              <h2 className="text-xl font-semibold mb-5 pr-8">Revenue Boost and Cost Reduction</h2>
              <p className="first-letter:text-[200%]">MPACER's solutions empower enterprises to boost revenue and reduce operating expenses.</p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <div className="absolute flex right-0 top-0 p-2 text-6xl text-red-800">
                <RiNumber7 />
              </div>
              <h2 className="text-xl font-semibold mb-5 pr-8">Skilled and Passionate Team</h2>
              <p className="first-letter:text-[200%]">The team excels in web development, design, and digital strategy, focusing on intuitive user interfaces and understanding clients' unique needs.</p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-2xl">
              <div className="absolute flex right-0 top-0 p-2 text-6xl text-red-800">
                <RiNumber8 />
              </div>
              <h2 className="text-xl font-semibold mb-5 pr-8">Commitment to Client Success</h2>
              <p className="first-letter:text-[200%]">We are committed to helping clients achieve their business objectives and succeed in the digital world.</p>
            </div>
            <div className="bg-white flex items-center justify-center rounded-lg shadow-lg p-6">
              <ContactUsFormDrawer title="Lets Talk" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default WhyMpacer;
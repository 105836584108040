"use client";

import { useNavigate  } from "react-router-dom";
import { Card } from "flowbite-react";

function Article() {
  const navigate = useNavigate();

  const handleCardClick = (id) => {
    navigate(`/article/${id}`);
  };

  return (
    <section className="px-4 py-8 animate-section-fade">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8">Articles</h2>
        <div className="grid justify-items-stretch grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <Card
            onClick={() => window.location.href ='https://www.linkedin.com/pulse/why-i-chose-wsl-2-over-dual-boot-mpacersolutions-1ssef/?trackingId=3eMti4S%2BQq1IB26Vw8TwxQ%3D%3D'}
            className=" w-full cursor-pointer"
            imgAlt="Article Image"
            imgSrc="https://media.licdn.com/dms/image/D4D12AQHhMLEyZLmQqA/article-cover_image-shrink_720_1280/0/1721659269446?e=1728518400&v=beta&t=lbOiZ-PlkQx2gIsX3eerFZNbvn1wLidgIfkKmSkkXH4"
          >
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Why I Chose WSL-2 Over Dual Boot
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              From Dual Boot to WSL-2: My Journey to a Seamless IT Dev Setup on Windows 11.
            </p>
          </Card>
          <Card
            onClick={() => window.location.href = 'https://www.linkedin.com/pulse/mastering-drupal-cms-architecture-mpacersolutions-mob2c/?trackingId=mrZtvYsc%2BIBbtAvcx2MdFA%3D%3D'}
            className=" w-full cursor-pointer"
            imgAlt="Article Image"
            imgSrc="https://media.licdn.com/dms/image/D5612AQFLnEHKRWiM0Q/article-cover_image-shrink_720_1280/0/1721283738478?e=1728518400&v=beta&t=WE01xpljfT4JpOyENx1D6QPhXEVj8LQ0OcFtJ47g5Vk"
          >
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Mastering Drupal CMS Architecture
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              The primary goal of a CMS is to streamline the content management process, making it easier to organize, update, and publish content.
            </p>
          </Card>
          <Card
            onClick={() => window.location.href = 'https://www.linkedin.com/posts/mpacersolutions_react-router-concepts-activity-7229040068475203584-AfgC?utm_source=share&utm_medium=member_desktop'}
            className=" w-full cursor-pointer"
            imgAlt="Article Image"
            imgSrc="https://lh3.googleusercontent.com/pw/AP1GczNZExbiTfwa-eeHFUd54DcfNCpNVAE5Au1pFX95JhAgt-h8dKkRJ411mioUE314f8ozp6AntJ1fysqKQyf3smZBF39bvTAFrxF8cAvG6igpaIguT-h_5CVBygPJqN9utGN9Z0TAHCDtknsiDl0q-S4Z=w300-h168-s-no-gm"
          >
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              React Router Concepts
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              In this quickly introduce you to the primary features of React Router.React Router concepts will help you to all type of applications.
            </p>
          </Card>
        </div>
        <div className="mt-4 grid justify-items-stretch grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <Card
            onClick={() => window.location.href ='https://www.linkedin.com/pulse/react-lifecycle-mpacersolutions-cwizc/?trackingId=rt%2BTKfN1VeA6irfDIhspFw%3D%3D'}
            className="w-full cursor-pointer"
            imgAlt="Article Image"
            imgSrc="https://lh3.googleusercontent.com/pw/AP1GczPFNSayYeuz7wE8gtcSfRFsxAfAcPkEFG1RZux-IL8kJ1KPINeT3zAzAUzWFErEPRqFFGNG0XjEJ_KL3W0qPNDGyZ7Aus78hCXQOQ0ICpyUlQ4xHr1mjnsyiN3YUbrRHz6quA-qSZIif4J9bslYyrUB=w542-h351-s-no-gm?authuser=0"
          >
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              React lifecycle Concepts
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              The primary goal of a CMS is to streamline the content management process, making it easier to organize, update, and publish content.
            </p>
          </Card>
          <Card
            onClick={() => window.location.href = 'https://www.linkedin.com/pulse/power-yoga-self-society-mpacersolutions-ywqsc/?trackingId=2ikGyceRFQnYaUWcuWsy%2BA%3D%3D'}
            className=" w-full cursor-pointer"
            imgAlt="Article Image"
            imgSrc="https://media.licdn.com/dms/image/v2/D5612AQHPIcAvN-YXZA/article-cover_image-shrink_423_752/article-cover_image-shrink_423_752/0/1718879388964?e=1730937600&v=beta&t=u6x7cuvMyZATgFydqkK_P-31kMrzTHsQYJHNNOmqvEw"
          >
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Power of Yoga
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Power of Yoga for Self and Society. Read this wonderful article to know the benefits of Yoga and ways to apply yoga in your everyday life.
            </p>
          </Card>

          <Card
            onClick={() => window.location.href = 'https://www.linkedin.com/pulse/big-picture-thinking-its-magic-mpacersolutions-tbyyc/?trackingId=lOR02ct5HDis%2B9DoHAhm4Q%3D%3D'}
            className=" w-full cursor-pointer"
            imgAlt="Article Image"
            imgSrc="https://media.licdn.com/dms/image/v2/D5612AQGbMGL488Tpbw/article-cover_image-shrink_423_752/article-cover_image-shrink_423_752/0/1718173140698?e=1730937600&v=beta&t=annzy5R_LGuUpsJ3WBrkwSDaW1IEwpjmxxK5S7BsvYc"
          >
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Big Picture Thinking -It's Magic.
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              The primary goal of a CMS is to streamline the content management process, making it easier to organize, update, and publish content.
            </p>
          </Card>
        </div>
      </div>
    </section>
  );
}

export default Article;

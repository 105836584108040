
import { Button, Drawer } from "flowbite-react";
import { useState } from "react";

export function ContactUsFormDrawer({ title, extraButtonClasses }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <div className="flex min-h-[10vh] items-center justify-center">
        <Button
          outline
          gradientDuoTone="purpleToBlue"
          className={extraButtonClasses}
          onClick={() => setIsOpen(true)}
        >
          {title}
        </Button>
      </div>
      <Drawer
        open={isOpen}
        onClose={handleClose}
        position="right"
        className="w-full md:w-2/5 h-[100vh]"
      >
        <Drawer.Header title="Contact Us" />
        <Drawer.Items>
          <iframe
            title={title}
            src="https://docs.google.com/forms/d/e/1FAIpQLScLI-yHR4GjY2yjgv0iWk7wW_VpmVkLhQ32-CTV83iPJF4ENw/viewform?embedded=true"
            width="100%"
            height="1500"
            scrolling="no"
            style={{ border: "0", margin: "0" }}
          >
            Loading…
          </iframe>
        </Drawer.Items>
      </Drawer>
    </>
  );
}

import React from 'react';
import { NavBar } from './NavBar.tsx';
const Header = () => {
  return (
    <header className="bg-white shadow">
     <h1> <NavBar /></h1>
    </header>
  );
};

export default Header;

"use client";

import { Footer } from "flowbite-react";
import { FaLinkedin, FaFacebookSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Footerpage() {

  const theme = {
    "root": {
      "base": "text-white-500 w-full bg-white shadow dark:bg-gray-800 md:flex md:items-center md:justify-between",

    },
  };
  return (

    <Footer container bgDark theme={theme}>
      <Footer.Copyright className="text-brand-white" by="MPACER Solutions Private Limited" year={2024} />
      <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
        <Footer.Icon aria-label="linkedinicon" className="text-brand-white" href="https://www.linkedin.com/company/mpacersolutions" target='_blank' icon={FaLinkedin} />
        <Footer.Icon aria-label="fbicon" className="text-brand-white" href="https://www.facebook.com/people/MPacer-Solutions/100069582885209/" target='_blank' icon={FaFacebookSquare} />
        <Footer.Icon aria-label="twittericon" className="text-brand-white" href="https://x.com/MPACERSolutions" target='_blank' icon={FaXTwitter} />
      </div>
    </Footer>
  );
}
export default Footerpage;


"use client";

import { List } from "flowbite-react";
import { HiCheck } from "react-icons/hi";

function Hero() {
  return (
    <div className="relative flex content-center items-center justify-center bg-gradient-to-b from-white to-brand-blue min-h-[60vh] md:min-h-screen">
      <div className="absolute top-0 w-full h-full bg-center bg-fixed bg-cover bg-no-repeat" style={{ backgroundImage: "url('mpacer-icon.svg')" }}>
        <span id="blackOverlay" className="w-full h-full absolute opacity-60 bg-black"></span>
      </div>

      <div className="container relative mx-auto">
        <div className="items-center flex flex-wrap">
          <div className="text-white w-full lg:w-6/12 px-4 ml-auto mr-auto text-center bg-brand-blue bg-opacity-90 pt-4 pb-4">
            <div>
              <h1 className="text-5xl m-6">
                We Develop Softwares
              </h1>
              <div>
                <List horizontal className="text-white space-x-2 md:space-x-4">
                  <List.Item icon={HiCheck}>Scalable</List.Item>
                  <List.Item icon={HiCheck}>Reliable</List.Item>
                  <List.Item icon={HiCheck}>Robust</List.Item>
                  <List.Item icon={HiCheck}>Economical</List.Item>
                </List>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Hero;

"use client";

function Annourcement() {
  return (
    <>

      <section className="flex bg-white dark:bg-gray-900 bg-none sm:bg-contain bg-left-bottom bg-no-repeat" style={{ backgroundImage: "url(confetti-7951019_1280.png)" }}>
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
          <h2 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">We’ve Evolved!</h2>
          <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">New Vibes, Modern Tools, Same Excellence!</p>
          <div className="flex  items-center justify-center">
            <div className="flex justify-center p-4 shadow-lg">
              <img alt="Brand Image" src="brand-refresh-illustration.png" className="" />
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
export default Annourcement;

"use client";

import {
  TbBrandDrupal,
  TbBrandHtml5
} from "react-icons/tb";
import { List } from "flowbite-react";
import { FaCircleDot } from "react-icons/fa6";
import { PiDeviceMobileSpeakerBold } from "react-icons/pi";
import { ContactUsFormDrawer } from "./ContactUsFormDrawer.tsx";
import { RiKakaoTalkLine } from "react-icons/ri";

function Services() {
  return (
    <>
      <section className="px-4 py-8 relative bg-gradient-to-b from-blue-100 to-white animate-section-fade">
        <div className="container  relative mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8">
            Services
          </h2>
          <div className="flex flex-wrap justify-between items-stretch">

            <div className="md:order-2 text-center w-full md:w-4/12 max-w-md md:px-2">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg shadow-md transform hover:-translate-y-1 duration-300 hover:shadow-xl">
                <div className="px-4 py-5 px-4 py-5 shadow-md animated-brand-bg">
                  <div className="p-3 text-white text-5xl inline-flex items-center justify-center w-16 h-16 mb-5 rounded-full bg-red-800" >
                    <TbBrandDrupal />
                  </div>
                  <h2 className="text-xl font-semibold mb-4 text-white">Drupal CMS Development</h2>

                  <div className="mt-2 mb-4">
                    <List className="mr-auto ml-auto w-3/4 md:w-1/2 text-white">
                      <List.Item icon={FaCircleDot}>Support</List.Item>
                      <List.Item icon={FaCircleDot}>Migration</List.Item>
                      <List.Item icon={FaCircleDot}>Upgrade</List.Item>
                      <List.Item icon={FaCircleDot}>Localization</List.Item>
                      <List.Item icon={FaCircleDot}>Decoupled</List.Item>
                    </List>
                  </div>
                </div>
              </div>
            </div>

            <div className="md:order-1 text-center w-full md:w-4/12 max-w-md pt-6 md:pr-4 md:pl-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg shadow-md transform hover:-translate-y-1 duration-300 hover:shadow-xl">
                <div className="px-4 py-5 shadow-md">
                  <div className="p-3 text-white text-5xl inline-flex items-center justify-center w-16 h-16 mb-5 rounded-full bg-red-800" >
                    <TbBrandHtml5 />
                  </div>
                  <h2 className="text-xl font-semibold mb-4">Modern UI Development</h2>
                  <div className="mt-2 mb-4 ">
                    <List className="mr-auto ml-auto w-3/4 md:w-1/2">
                      <List.Item icon={FaCircleDot}>AngularJS</List.Item>
                      <List.Item icon={FaCircleDot}>ReactJS</List.Item>
                      <List.Item icon={FaCircleDot}>NextJS</List.Item>
                      <List.Item icon={FaCircleDot}>MEARN Stack</List.Item>
                      <List.Item icon={FaCircleDot}>MEAN Stack</List.Item>
                    </List>
                  </div>
                </div>
              </div>
            </div>

            <div className="md:order-3 text-center w-full md:w-4/12 max-w-md pt-6 md:pl-4 md:pr-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg shadow-md transform hover:-translate-y-1 duration-300 hover:shadow-xl">
                <div className="px-4 py-5 px-4 py-5 shadow-md">
                  <div className="p-3 text-white text-5xl inline-flex items-center justify-center w-16 h-16 mb-5 rounded-full bg-red-800" >
                    <PiDeviceMobileSpeakerBold />
                  </div>
                  <h2 className="text-xl font-semibold mb-4">Mobile Development</h2>
                  <div className="mt-2 mb-4 ">
                    <List className="mr-auto ml-auto w-3/4 md:w-1/2">
                      <List.Item icon={FaCircleDot}><div>API Development</div></List.Item>
                      <List.Item icon={FaCircleDot}>Native App</List.Item>
                      <List.Item icon={FaCircleDot}>Web App</List.Item>
                      <List.Item icon={FaCircleDot}>Hybrid App</List.Item>
                      <List.Item icon={FaCircleDot}>Progressive Web App</List.Item>
                    </List>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="md:order-3 text-center w-full md:w-3/4 md:mr-auto md:ml-auto pt-6 px-4 md:pl-4 md:pr-0">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg shadow-md transform hover:-translate-y-1 duration-300 hover:shadow-xl">
            <div className="px-4 py-5 px-4 py-5 shadow-md">
              <div className="p-3 text-white text-5xl inline-flex items-center justify-center w-16 h-16 mb-5 rounded-full bg-red-800" >
                <RiKakaoTalkLine />
              </div>
              <h2 className="text-xl font-semibold mb-2">Software Consulting</h2>
              <p className="mb-4 text-justify first-letter:text-[200%]">
                Are you ready to establish your business's online identity? Embarking on a
                digital transformation journey? Our expertise in the digital realm can guide you
                through technical aspects-whether it's choosing the right technology stack,
                finding cost-effective hosting solutions, or ensuring a seamless transition.
                Regardless of your business's size, we treat every endeavor with equal importance.
                We believe in open thought sharing without any financial strings attached.
                Don't hesitate to reach out; we'd be delighted to assist you on your digital
                transformation path!
              </p>
            </div>
          </div>
          <ContactUsFormDrawer title="Lets Talk" />
        </div>

      </section>

    </>
  );
}
export default Services;
"use client";

import { Navbar } from "flowbite-react";
import { ContactUsFormDrawer } from "./ContactUsFormDrawer.tsx";
import { useLocation, Link, Outlet } from "react-router-dom";
import classNames from "classnames";

export function NavBar() {
    const location = useLocation();

    const navbarTheme = {
        root: {
            base: "bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600",
            inner: {
                base: "mx-auto flex flex-wrap items-center justify-between",
            },
        },
    };

    const getLinkClass = (href) =>
        classNames("cursor-pointer block p-4 md:p-0 hover:bg-gray-50 md:hover:bg-transparent", {
            active: location.pathname === href,
        });

    return (
        <>
            <Navbar fluid rounded theme={navbarTheme}>
                <Navbar.Brand href="/">
                    <img src="mpacer-logo.svg" className="w-full h-auto mr-3 sm:h-16" width="160" alt="MPACER Solutions Pvt Ltd Logo" />
                </Navbar.Brand>
                <div className="flex md:order-2">
                    <ContactUsFormDrawer title="Contact Us" extraButtonClasses="hidden sm:block" />
                    <Navbar.Toggle />
                </div>
                <Navbar.Collapse>
                    <Navbar.Link as={Link} to="/" className={getLinkClass("/")}>
                        Home
                    </Navbar.Link>
                    <Navbar.Link as={Link} to="/services" className={getLinkClass("/services")}>
                        Services
                    </Navbar.Link>
                    <Navbar.Link as={Link} to="/about-us" className={getLinkClass("/about-us")}>
                        About Us
                    </Navbar.Link>
                    <Navbar.Link as={Link} to="/why-mpacer" className={getLinkClass("/why-mpacer")}>
                        Why MPACER
                    </Navbar.Link>
                    <Navbar.Link as={Link} to="/articles" className={getLinkClass("/articles")}>
                        Articles
                    </Navbar.Link>
                </Navbar.Collapse>
            </Navbar>
            <Outlet />
        </>
    );
}
